<template>
  <div id="app" :class="{ mobile: isMobile }">
    <!-- <div class="head">
       <img src="https://www.uptick.network/assets/img/01/Uptick%20Network%20logo.svg" class="logo"  height="43" alt="icon">
      </div> -->
    <div class="main " v-if="!isMobile">
      <div class="topImage">
        <img src="@/assets/image/logo01.png" width="440" alt="icon">
        <div class="uptick_des_top">
          Bringing Web3 Transformation into the Real Economy</div>

      </div>
      <div class="expertise">
        <div class="contain">
          <div class="description">
            <div class="item">
              <img src="@/assets/image/2_01.png" width="130" alt="icon">
              <div class="font16 mt-4"> Web3 Transformation Consulting </div>

            </div>
            <div class="item">
              <img src="@/assets/image/2_02.png" width="130" alt="icon">
              <div class="font16 mt-4"> Web3 Protocols and Services Development </div>

            </div>
            <div class="item">
              <img src="@/assets/image/2_03.png" width="130" alt="icon">
              <div class="font16 mt-4"> Web3 Cloud Services </div>

            </div>
            <div class="item">
              <img src="@/assets/image/2_04.png" width="130" alt="icon">
              <div class="font16 mt-4"> Ecosystem Application Development and Operation
              </div>

            </div>
          </div>
          <div class="expertise_title">Our Expertise</div>

          <div class="expertise_des">
            We leverage Uptick’s public infrastructure to develop commercial protocols and modules that empower the real
            economy. We provide Web3-related cloud services, develop and operate typical Web3 commercial applications,
            and offer Web3 transformation consulting and development services to traditional enterprises.



          </div>


        </div>

      </div>
      <div class="protocols">
        <img src="@/assets/image/3_1.png" width="790" alt="icon">
        <div class="web3Real">Web3 Real Economy Protocols</div>
        <img src="@/assets/image/3_2.png" width="790" alt="icon">

      </div>
      <div class="business">
        <div class="service">
          <div class="web3Title">Web3 Modular Business Services</div>
          <div class="list">
            <div class="item">· Loyalty and Rights Management</div>
            <div class="item">· Decentralized Data Service</div>
            <div class="item">· GreenTech Service</div>
            <div class="item">· Decentralized Interchain NFT Interoperability and Usability Engine</div>
            <div class="item">· Decentralized CRM and Uptick Social DAO</div>
            <div class="item">· Omni-Payment and Wallet Service</div>
            <div class="item">· Asset Vault and Trading Service</div>
            <img src="@/assets/image/4_01.png" class="serviceImg" width="584" alt="icon">

          </div>
        </div>
        <div class="Landscape">
          <div class="web3Title">Web3 Landscape</div>
          <div class="list">
            <div class="item">· Wallet and Payment Service for Better Usability</div>
            <div class="item">· Web3 Cloud Solutions</div>
            <div class="item">· Web3 Tourist Destination Services</div>
            <div class="item">· RWA and DePIN</div>
            <div class="item">· Web3 Charity, Social Enterprise, ESG, CSR</div>
            <div class="item">· Web3 Social Commerce and Marketing</div>
            <div class="item">· Web3 Gaming</div>
            <div class="item">· Full Spectrum Digital Asset Marketplace</div>
            <img src="@/assets/image/4_02.png" class="serviceImg" width="530" alt="icon">

          </div>
        </div>

      </div>
      <div class="footer d-flex flex-column">
      <img src="@/assets/image/logo01.png" width="167" alt="icon">
      <div class="footTitle">Uptick Network Limited is an incubatee company of HKSTP.</div>
      <div class="footTitle">You can reach us at: </div>
      <a href="mailto:askuptick@upticknet.com" target="_blank">
        askuptick@upticknet.com
      </a>
      <div class="footTitle">10/F 19W Hong Kong Science and Technology Park, Hong Kong</div>


      <img src="@/assets/image/icon_huojian.png" @click="scrollToTop" class="huojian" width="40" alt="icon">

    </div>

    </div>
    <div class="mobileMain" v-if="isMobile">
      <div class="topImage">
        <img src="@/assets/image/logo01.png" width="205" alt="icon">
       
         <div class="uptick_des mt-1"> Bringing Web3 Transformation into the Real Economy</div>

      </div>
      <div class="mobileExpertise">
        <div class="description">
          <div class="title-25">Our Expertise</div>
          <div class="title-12">We leverage Uptick’s public infrastructure to develop commercial protocols and modules
            that empower the real
            economy. We provide Web3-related cloud services, develop and operate typical Web3 commercial applications,
            and offer Web3 transformation consulting and development services to traditional enterprises.</div>
        </div>
        <div class="list">
          <div class="item">
            <img src="@/assets/image/2_01.png" width="85" alt="icon">
              <div class="font13 mt-4"> Web3 Transformation Consulting </div>

          </div>
          <div class="item" style="margin-left: 4%;">
            <img src="@/assets/image/2_02.png" width="85" alt="icon">
            <div class="font13 mt-4"> Web3 Protocols and Services Development </div>
          </div>
          <div class="item">
            <img src="@/assets/image/2_03.png" width="85" alt="icon">
            <div class="font13 mt-4"> Web3 Cloud Services </div>
          </div>
          <div class="item" style="margin-left: 4%;">
            <img src="@/assets/image/2_04.png" width="85" alt="icon">
              <div class="font13 mt-4"> Ecosystem Application Development and Operation
              </div>
          </div>

        </div>

      </div>

      <!-- <div class="protocols">
        <img src="@/assets/image/3_1.png" width="790" alt="icon">
        <div class="web3Real">Web3 Real Economy Protocols</div>
        <img src="@/assets/image/3_2.png" width="790" alt="icon">

      </div> -->
      <div class="mobileProtocols">
        <div class="topInfo">
          <img src="@/assets/image/3_1.png" width="100%" alt="icon">
        </div>
        <div class="midiemInfo">
          Web3 Real Economy 
          Protocols
        </div>
        <div class="topInfo">
          <img src="@/assets/image/3_2.png" width="100%" alt="icon">

        </div>
      </div>
      <div class="mobileBusiness">
          <div class="service">
            <div style="display: flex;flex-direction: row;height: 150px;align-items: center;">
              <div class="title21">Web3 Modular Business Services</div>
              <img src="@/assets/image/4_01.png" class="mobileServiceImg" width="200" alt="icon">
            </div>
            <div class="containter">
              <div class="item">· Loyalty and Rights Management</div>
            <div class="item">· Decentralized Data Service</div>
            <div class="item">· GreenTech Service</div>
            <div class="item">· Decentralized Interchain NFT Interoperability and Usability Engine</div>
            <div class="item">· Decentralized CRM and Uptick Social DAO</div>
            <div class="item">· Omni-Payment and Wallet Service</div>
            <div class="item">· Asset Vault and Trading Service</div>

            </div>
           


          </div>
          <div class="service">
            <div style="display: flex;flex-direction: row;height: 150px;align-items: center;margin-top: 35px;">
              <div class="title21">Web3 Landscape</div>
              <img src="@/assets/image/4_02.png" class="mobileServiceImg" width="200" alt="icon">
            </div>
            <div class="containter">
              <div class="item">· Wallet and Payment Service for Better Usability</div>
            <div class="item">· Web3 Cloud Solutions</div>
            <div class="item">· Web3 Tourist Destination Services</div>
            <div class="item">· RWA and DePIN</div>
            <div class="item">· Web3 Charity, Social Enterprise, ESG, CSR</div>
            <div class="item">· Web3 Social Commerce and Marketing</div>
            <div class="item">· Web3 Gaming</div>
            <div class="item">· Full Spectrum Digital Asset Marketplace</div>

            </div>
           


          </div>
      </div>
      <div class="mobileFooter d-flex flex-column">
      <img src="@/assets/image/logo01.png" width="167" alt="icon">
      <div class="footTitle">Uptick Network Limited is an incubatee company of HKSTP.</div>
      <div class="footTitle">You can reach us at: </div>
      <a href="mailto:askuptick@upticknet.com" target="_blank">
        askuptick@upticknet.com
      </a>
      <div class="footTitle">10/F 19W Hong Kong Science and Technology Park, Hong Kong</div>


      <!-- <img src="@/assets/image/icon_huojian.png" @click="scrollToTop" class="huojian" width="40" alt="icon"> -->

    </div>

    </div>
    <!-- <div class="footer d-flex flex-column">
      <img src="@/assets/image/logo01.png" width="167" alt="icon">
      <div class="footTitle">Uptick Network Limited is an incubatee company of HKSTP.</div>
      <div class="footTitle">You can reach us at: </div>
      <a href="mailto:askuptick@upticknet.com" target="_blank">
        askuptick@upticknet.com
      </a>
      <div class="footTitle">10/F 19W Hong Kong Science and Technology Park, Hong Kong</div>


      <img src="@/assets/image/icon_huojian.png" @click="scrollToTop" class="huojian" width="40" alt="icon">

    </div> -->



  </div>

</template>

<script>

import '@/assets/style/public.css'
let time;
// 引入 Twitter API

export default {
  name: "Home",


  data: () => ({



  }),
  computed: {
    isMobile: function () {

      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {

  },
  filters: {

  },

  watch: {

  },

  methods: {
    scrollToTop() {
      // 平滑滚动到页面顶部
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }

  },
};
</script>

<style lang="scss" scoped>
.main {
  .font16 {
    min-height: 70px;
    width: 70%;
    text-align: center;
    font-family: AmpleSoft-Medium;
    font-size: 16px;
    color: #ffffff;
  }

  .topImage {
    width: 100%;
    height: 900px;
    background-size: cover;
    background-image: url('@/assets/image/image01.jpg');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;

  }

  .uptick_des {
    width: 480px;
    text-align: center;
    line-height: 50px;
    font-family: AmpleSoft-Bold !important;
    font-size: 35px;
    letter-spacing: 0px;
    color: #ffffff;
    // box-shadow: 0px 0px 11px 5px 
    // 	rgba(1, 0, 43, 0.61);
    text-shadow: 2px 2px 5px rgba(1, 0, 43, 0.61);


  }

  .expertise {
    width: 100%;
    padding-top: 90px;
    height: 850px;
    background-size: cover;
    background-image: url('@/assets/image/bg_2.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;

    .description {
      display: flex;
      flex-direction: row;
      margin-top: -150px;
      margin-left: 20px;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 245px;
        margin-left: 20px;
        height: 300px;
        background-image: linear-gradient(-34deg,
            #07090d 0%,
            #442845 100%),
          linear-gradient(#766984,
            #766984);
        background-blend-mode: normal,
          normal;
        border-radius: 40px;
      }
    }

    .expertise_title {
      margin-top: 47px;
      width: 100%;
      text-align: center;
      height: 41px;
      font-family: AmpleSoft-Bold;
      font-size: 43px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;
    }

    .expertise_des {
      margin-top: 25px;
      width: 100%;
      padding-left: 14%;
      padding-right: 14%;
      font-family: AmpleSoft-Medium;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;

    }

  }

  .contain {
    width: 1140px;
    height: 490px;
    background-color: #000000;
    border-radius: 50px;
    border: solid 10px #ffffff;

  }

  .protocols {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background-size: cover;
    background-image: url('@/assets/image/bg_3.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;

    .web3Real {
      width: 790px;
      height: 138px;
      border-radius: 50px;
      border: solid 10px #000000;
      justify-content: center;
      align-items: center;
      display: flex;
      font-family: AmpleSoft-Bold;
      font-size: 43px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ffffff;
      margin-top:28px;
      margin-bottom: 28px;
    }

  }
  .uptick_des_top{
    width: 590px;
    text-align: center;
    line-height: 50px;
    font-family: AmpleSoft-Bold !important;
    font-size: 43px;
    letter-spacing: 0px;
    color: #ffffff;
    // box-shadow: 0px 0px 11px 5px 
    // 	rgba(1, 0, 43, 0.61);
    text-shadow: 2px 2px 5px rgba(1, 0, 43, 0.61);
    margin-top: 20px;
  }

  .business {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 200px;
    background-size: cover;
    background-image: url('@/assets/image/bg_2.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;

    .service {
      width: 1110px;

      .web3Title {

        font-family: AmpleSoft-Bold;
        font-size: 43px;
        font-weight: normal;
        font-stretch: normal;

        letter-spacing: 0px;
        color: #ffffff;
      }

      .list {
        margin-top: 30px;
        width: 100%;
        height: 346px;
        background-color: #000000;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 50px;
        position: relative;

        .serviceImg {
          position: absolute;
          top: -20px;
          right: 0;
        }

        .item {
          width: 380px;
          font-family: AmpleSoft-Medium;
          font-size: 20px;
          font-weight: normal;
          letter-spacing: 0px;
          color: #ffffff;
          line-height: 35px;

        }

      }

    }

    .Landscape {
      width: 1110px;
      margin-top: 185px;


      .web3Title {
        text-align: right;
        font-family: AmpleSoft-Bold;
        font-size: 43px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
      }

      .list {
        margin-top: 30px;
        width: 100%;
        height: 346px;
        background-color: #000000;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 40px;
        position: relative;
        align-items: flex-end;

        .serviceImg {
          position: absolute;
          top: 20px;
          left: 0;
        }

        .item {
          width: 440px;
          font-family: AmpleSoft-Medium;
          font-size: 20px;
          font-weight: normal;
          letter-spacing: 0px;
          line-height: 35px;
          color: #ffffff;

        }

      }

    }

  }

}

.mobileMain {
  .topImage {
    width: 100%;
    height: 425px;
    background-size: cover;
    background-image: url('@/assets/image/h5_image01.jpg');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;

  }

  .uptick_des {
    width: 280px;
    text-align: center;
    font-family: AmpleSoft-Bold !important;
    font-size: 19px;
    line-height: 25px;
    letter-spacing: 0px;
    color: #ffffff;
    // box-shadow: 0px 0px 11px 5px 
    // 	rgba(1, 0, 43, 0.61);
    text-shadow: 2px 2px 5px rgba(1, 0, 43, 0.61);


  }

  .mobileExpertise {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    background-size: cover;
    background-image: url('@/assets/image/bg_2.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;

    .description {
      width: 90%;
      padding-top: 35px;
      padding-bottom: 35px;
      padding-left: 30px;
      padding-right: 30px;

      background-color: #000000;
      border-radius: 35px;
      border: solid 10px #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title-25 {
        font-family: AmpleSoft-Bold;
        font-size: 25px;
        font-weight: normal;
        font-stretch: normal;

        letter-spacing: 0px;
        color: #ffffff;
      }

      .title-12 {
        text-align: center;
        font-family: AmpleSoft-Medium;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #ffffff;
      }
    

    }
    .list{
        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .item{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          width: 48%;
          padding-bottom: 20px;
          padding-top:20px;
	background-image: linear-gradient(-34deg, 
		#07090d 0%, 
		#442845 100%), 
	linear-gradient(
		#766984, 
		#766984);
	background-blend-mode: normal, 
		normal;
	border-radius: 35px;
  .font13{
    min-height: 90px;
    width: 80%;
    text-align: center;
    font-family: AmpleSoft-Medium;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
  }
        }
      }

  }
  .mobileProtocols{
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    background-size: cover;
    background-image: url('@/assets/image/bg_3.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    .topInfo{
      display: flex;
      flex-direction: row;
      width: 90%;
      .protoImage{
        width: 24%;
      }
    }
    .midiemInfo{
      margin-top: 28PX;
      margin-bottom: 28px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: 101px;
	border-radius: 35px;
	border: solid 10px #000000;
  font-family: AmpleSoft-Bold;
	font-size: 25px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 0px;
	color: #ffffff;
    }

  }
  .mobileBusiness{
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    background-size: cover;
    background-image: url('@/assets/image/bg_2.png');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    .service{
      width: 90%;
      display: flex;
      flex-direction: column;
      .mobileServiceImg{
        z-index: 10;
      }
      .title21{
        width: 50%;
        font-family: AmpleSoft-Bold;
	font-size: 21px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 0px;
	color: #ffffff;
      }
      .containter{
        width: 100%;
       
	background-color: #000000;
	border-radius: 15px;
  margin-top: -15px;
  padding: 25px;
  .item{

    font-family: AmpleSoft-Medium;
	font-size: 15px;
	font-weight: normal;
	letter-spacing: 0px;
	color: #ffffff;
  line-height: 25px;
  }
      }
    }

  }

}

.mobileFooter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;
  padding-top: 25px;
  padding-bottom: 30px;
  background-color: #000000;

  .footTitle {
    font-family: AmpleSoft-Medium;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #ffffff;
    line-height: 18px;
  }

  .huojian {
    position: absolute;
    right: 40px;
  }

  a {
    color: #ffffff !important;
    font-size: 12px;
    line-height: 18px;

  }

}



.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 100%;
  padding-top: 50px;
  padding-bottom: 65px;
  background-color: #000000;

  .footTitle {
    font-family: AmpleSoft-Medium;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    
    color: #ffffff;
  }

  .huojian {
    position: absolute;
    right: 40px;
  }

  a {
    color: #ffffff !important;

  }

}
</style>