<template>
  <v-app>
    <v-main class="main">
      <Home/>
    </v-main>
  </v-app>
</template>

<script>
import Home from './views/home/index';

export default {
  name: 'App',

  components: {
    Home,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>

 
</style>
