import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import * as filters from "./filters";
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// 自定义全局组件 START
import uToast from "@/components/uToast.vue";
Vue.component("uToast", uToast);
// import basePage from "@/layout/BasePage.vue";
import uComponents from "@/components/uComponents.vue"
Vue.component("uComponents", uComponents);
// 全局提示框
//     type:success,error
Vue.prototype.$toast = function(type, txt, timer) {
  return this.$refs.ucom.toast(type, txt, timer);
}
Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
